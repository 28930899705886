exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-job-description-js": () => import("./../../../src/pages/careers/job-description.js" /* webpackChunkName: "component---src-pages-careers-job-description-js" */),
  "component---src-pages-careers-jobs-js": () => import("./../../../src/pages/careers/jobs.js" /* webpackChunkName: "component---src-pages-careers-jobs-js" */),
  "component---src-pages-careers-location-argentina-js": () => import("./../../../src/pages/careers/location-argentina.js" /* webpackChunkName: "component---src-pages-careers-location-argentina-js" */),
  "component---src-pages-careers-location-bolivia-js": () => import("./../../../src/pages/careers/location-bolivia.js" /* webpackChunkName: "component---src-pages-careers-location-bolivia-js" */),
  "component---src-pages-careers-location-mexico-js": () => import("./../../../src/pages/careers/location-mexico.js" /* webpackChunkName: "component---src-pages-careers-location-mexico-js" */),
  "component---src-pages-careers-location-peru-js": () => import("./../../../src/pages/careers/location-peru.js" /* webpackChunkName: "component---src-pages-careers-location-peru-js" */),
  "component---src-pages-careers-location-spain-js": () => import("./../../../src/pages/careers/location-spain.js" /* webpackChunkName: "component---src-pages-careers-location-spain-js" */),
  "component---src-pages-careers-location-tunisia-js": () => import("./../../../src/pages/careers/location-tunisia.js" /* webpackChunkName: "component---src-pages-careers-location-tunisia-js" */),
  "component---src-pages-careers-location-uruguay-js": () => import("./../../../src/pages/careers/location-uruguay.js" /* webpackChunkName: "component---src-pages-careers-location-uruguay-js" */),
  "component---src-pages-careers-location-usa-js": () => import("./../../../src/pages/careers/location-usa.js" /* webpackChunkName: "component---src-pages-careers-location-usa-js" */),
  "component---src-pages-careers-locations-js": () => import("./../../../src/pages/careers/locations.js" /* webpackChunkName: "component---src-pages-careers-locations-js" */),
  "component---src-pages-caseaisecurity-js": () => import("./../../../src/pages/caseaisecurity.js" /* webpackChunkName: "component---src-pages-caseaisecurity-js" */),
  "component---src-pages-casecartier-js": () => import("./../../../src/pages/casecartier.js" /* webpackChunkName: "component---src-pages-casecartier-js" */),
  "component---src-pages-casefuturepay-js": () => import("./../../../src/pages/casefuturepay.js" /* webpackChunkName: "component---src-pages-casefuturepay-js" */),
  "component---src-pages-caselegion-js": () => import("./../../../src/pages/caselegion.js" /* webpackChunkName: "component---src-pages-caselegion-js" */),
  "component---src-pages-casesmarthings-js": () => import("./../../../src/pages/casesmarthings.js" /* webpackChunkName: "component---src-pages-casesmarthings-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-educationgb-js": () => import("./../../../src/pages/educationgb.js" /* webpackChunkName: "component---src-pages-educationgb-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internship-js": () => import("./../../../src/pages/internship.js" /* webpackChunkName: "component---src-pages-internship-js" */),
  "component---src-pages-mentoring-js": () => import("./../../../src/pages/mentoring.js" /* webpackChunkName: "component---src-pages-mentoring-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-qaservices-tsx": () => import("./../../../src/pages/qaservices.tsx" /* webpackChunkName: "component---src-pages-qaservices-tsx" */),
  "component---src-pages-refer-a-friend-js": () => import("./../../../src/pages/refer-a-friend.js" /* webpackChunkName: "component---src-pages-refer-a-friend-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-job-description-js": () => import("./../../../src/templates/job-description.js" /* webpackChunkName: "component---src-templates-job-description-js" */)
}

