/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "@fontsource/montserrat"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"

import "@fontsource/volkhov"
import "@fontsource/volkhov/700.css"

import "@fontsource/vollkorn-sc"

import "@fontsource/lato"

export const onRouteUpdate = ({ location }) => {
  const hash = location.hash

  if (hash) {
    const element = document.querySelector(hash)
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }
}

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location
  const currentPosition = getSavedScrollPosition(location)

  // Define las rutas que deben desplazarse a la parte superior
  const scrollToTopRoutes = [
    `/`,
    `/careers/locations/`,
    `/careers/jobs/`,
    `/about/`,
    `/services/`,
    `/products/`,
    `/qaservices/`,
    `/internship/`,
    `/mentoring/`,
    `/education/`,
    `/careers/`,
    `/privacy-policy/`,
    `/sitemap/`,
    `/refer-a-friend/`,
  ]

  const translatedRoutes = scrollToTopRoutes.map(route => `/es${route}`)
  const allScrollToTopRoutes = [...scrollToTopRoutes, ...translatedRoutes]

  const isScrollToTopRoute =
    allScrollToTopRoutes.includes(pathname) ||
    pathname.startsWith("/careers/location-") ||
    pathname.startsWith("/es/careers/location-")

  if (isScrollToTopRoute) {
    window.scrollTo(0, 0)
    return false
  }

  return true
}
